import React from 'react'

const Title = ({title}) => {
  return (
    <>
        <h2 className='section-title'>{title}</h2>
    </>
  )
}

export default Title